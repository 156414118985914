<template>
  <div>
    <div class="mb-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="flex">
      <div class="w-full">
        <form @submit.prevent="store(prelation)" @input="removeError($event.target.id)">
          <h1 class="text-2xl my-4">
            Crear prelación
          </h1>
          <div class="flex flex-wrap -mx-2">
              <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                <template>
                  <h2 class="text-xl mb-4">
                    1) Seleccione la especialidad, semestre y tipo de prelación
                  </h2>
                  <a-select
                    filterable
                    label="Especialidad"
                    required
                    id="specialization_id"
                    :loading="loading"
                    :source="mappedSpecializations"
                    :error="error('specialization_id')"
                    v-model="prelation.specialization_id" />

                  <a-select
                    filterable
                    required
                    label="Semestre"
                    id="semester_id"
                    class="mt-4"
                    :loading="loading"
                    :source="mappedSemesters"
                    :error="error('originating_course_id')"
                    v-model="prelation.semester_id" />

                  <a-select
                    :disabled="!prelation.semester_id"
                    required
                    label="Tipo de prelación"
                    :source="[{label: 'PARCIAL', value: 'partial'}, {label: 'TOTAL', value: 'full'}]"
                    id:="type"
                    v-model="prelation.type" />
                </template>

                <template v-if="prelation.type && prelation.semester_id">

                  <h4 class="text-2xl mb-4">
                      
                      {{ `${periodSemesterSpecialization.period.year} - ${periodSemesterSpecialization.period.number}` }} |
                      {{ `${periodSemesterSpecialization.specialization.title}` }} |
                      {{ `Semestre ${periodSemesterSpecialization.semester.number}` }}
                  </h4>
                  <h2 class="text-xl mb-4" v-if="prelation.type == 'partial'">
                    2) Seleccione la materia que privará las materias del siguiente semestre
                  </h2>
                  <h2 class="text-xl mb-4" v-else>
                    2) Seleccione la materia que privará el <b>semestre {{ periodSemesterSpecialization.semester.number + 1 }}</b>
                  </h2>
                  <div>
                    <div class="form-group">
                      <label for="originating_course_id">Materia</label>
                      <a-select
                        filterable
                        id="originating_course_id"
                        :source="periodSemesterSpecialization.courses.map(it => ({ label: it.title, value: it.id }))"
                        :error="error('originating_course_id')"
                        v-model="prelation.originating_course_id" />
                    </div>
                    <span v-if="prelation.type == 'partial'">                
                      <template v-if="mappedNextSemesterCourses.length">
                        <div class="flex gap-2 flex-wrap py-2" v-if="prelation.originating_course_id">
                          <template v-for="course in filteredNextSemesterCourses">
                            <a-badge :key="course.id" @click="attach(course.id)" class="cursor-pointer">
                              {{ course.title }}
                            </a-badge>
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <template v-if="prelation.originating_course_id">
                          <template v-if="!filteredNextSemesterCourses.length">
                            <b>Parece que no hay datos para el siguiente semestre</b>
                          </template>
                        </template>
                      </template>
                    </span>

                    <!-- <p class="text-gray-600 my-4">
                      Si has seleccionado la especialidad, semestre o tipo de prelación equivocado, <span class="text-blue-400 cursor-pointer" @click="prelation.specialization_id = null; prelation.semester_id = null; prelation.type = null">presione aqui para regresar</span>
                    </p> -->
                  </div>
                </template>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/2 px-2" v-if="prelation.originating_course_id && prelation.type == 'partial'">
                <h5 class="text-gray-600">
                  Las siguientes materias requieren que el estudiante apruebe <b>{{ selectedCourse }}</b> para ser cursadas
                </h5>
                <div class="flex flex-wrap gap-2" v-if="prelation.type == 'partial'">
                  <a-badge v-for="(course, key) in prelation.target_courses_ids" :key="key" @close="dettach(key)">
                    {{ foundAttachedCourse(course) }}
                  </a-badge>
                </div>
                <div class="flex mt-2 space-x-2" v-if="prelation.target_courses_ids.length >= 1">
                  <a-button mode="primary" type="submit" :loading="loading">
                    Guardar
                  </a-button>
                  <a-button outlined :to="{ name: 'prelations' }">
                    Cancelar
                  </a-button>               
                </div> 
              </div>
              <div class="w-full md:w-1/2 lg:w-1/2 px-2" v-else-if="prelation.originating_course_id && prelation.type == 'full'">
                <h5 class="text-gray-600">
                  Las materia <b>{{ selectedCourse }}</b> es requerida para cursar el <b>semestre {{ periodSemesterSpecialization.semester.number + 1 }}</b>
                </h5>
                <div class="flex mt-2 space-x-2">
                  <a-button mode="primary" type="submit" :loading="loading">
                    Guardar
                  </a-button>
                  <a-button outlined :to="{ name: 'prelations' }">
                    Cancelar
                  </a-button>               
                </div> 
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    prelation: {
      period_id: null,
      specialization_id: null,
      semester_id: null,
      originating_course_id: null,
      type: null,
      target_courses_ids: [],
      target_course_id: null
    },
    period: {},
    loading: false,
  }),
  computed: {
    specializations() {
      return this.period?.pensums?.map?.($0 => $0.specialization) ?? []
    },
    pensum() {
      return this.period?.pensums?.find($0 => $0.specialization_id === this.prelation.specialization_id)
    },
    semesters() {
      return this.period?.pensums?.find?.($0 => $0.specialization_id === this.prelation.specialization_id)?.semesters ?? []
    },
    mappedSpecializations() {
      return this.specializations?.filter(a => a.type === "default").map?.($0 => ({ label: $0.title, value: $0.id }))
    },
    mappedSemesters() {
      return this.semesters.map?.($0 => ({ label: $0.number, value: $0.id })).sort((a, b) => a.label - b.label)
    },
    ...mapGetters({
      hasError: 'hasError',
      error: 'error'
    }),
    mappedNextSemesterCourses() {
      const { semester_id } = this.prelation

      if (semester_id) {
        const index = this.semesters.findIndex($0 => $0.id === semester_id)
        if (index >= 0) {
          return this.pensum.semesters[index + 1]?.sections.map($0 => $0.courses)
            ?.flatMap($0 => $0)
            .filter(($0, index, self) => self.findIndex($1 => $1.id === $0.id) === index) ?? []
        }
      }

      return []
    },
    filteredNextSemesterCourses() {
      return this.mappedNextSemesterCourses.filter(it => it.id !== this.prelation.originating_course_id && !this.prelation.target_courses_ids.includes(it.id))
    },
    periodSemesterSpecialization() {
      const { semester_id, specialization_id } = this.prelation,
        period = this.period,
        semester = period?.pensums?.flatMap($0 => $0.semesters)?.find($0 => $0.id === semester_id) ?? { sections: [] },
        courses = semester.sections.map($0 => $0.courses)
          ?.flatMap($0 => $0)
          .filter(($0, index, self) => self.findIndex($1 => $1.id === $0.id) === index) ?? [],
        specialization = this.specializations.find($0 => $0.id === specialization_id)


      return {
        period,
        semester,
        courses,
        specialization
      }
    },
    selectedCourse() {
      return this.periodSemesterSpecialization.courses
        .find($0 => $0.id === this.prelation.originating_course_id)?.title ?? '-'
    }
  },
  methods: {
    ...mapActions({
      store: 'prelations/store',
      removeError: 'removeError',
      fetchCoursesBySpecialization: 'periods/fetchCoursesBySpecialization'
    }),
    // selectedCourse() {
    //     let found = this.periodSemesterSpecialization.semester.courses.find(it => it.id === this.prelation.originating_course_id)
    //     if (found) return found.title
    //     return '-'
    // },
    foundAttachedCourse(id) {
      let found = this.mappedNextSemesterCourses.find(it => it.id === id)
      if (found) return found.title
      return '-'
    },
    attach(id) {
      this.prelation.target_courses_ids.push(id)
    },
    dettach(key) {
      this.prelation.target_courses_ids.splice(key, 1)
    }
  },
   watch: {
     'prelation.originating_course_id'(val) {
       if (!val) {
         this.prelation.originating_course_id = null
       } else {
         let foundIndex = this.prelation.target_courses_ids.findIndex(it => it === val)
         if (foundIndex >= 0) {
           this.prelation.target_courses_ids.splice(foundIndex, 1)
         }
       }
     },
     'prelation.specialization_id'() {
       this.prelation.originating_course_id = null
       this.prelation.target_courses_ids = []
       this.prelation.semester_id = null
       this.prelation.type = null
     },
     'prelation.type'(val) {
      if(val) this.prelation.originating_course_id = null;
      
     },
     'prelation.semester_id'() {
      this.prelation.originating_course_id = null;
      this.prelation.target_courses_ids = []
       this.prelation.type = null
     }
   },
  mounted() {
    this.loading = !this.loading
    this.$repository.periods.enabled({
      with: 'pensums.specialization,pensums.semesters.sections.courses'
    }).then(({ data }) => {
      this.period = data
      this.prelation.period_id = data.id
    })
      .finally(() => this.loading = !this.loading)
    // this.fetchPeriods({
    //     limit: 0,
    //     with: 'sections.courses,sections.specialization,pensums.semesters.specialization,pensums.semesters.courses'
    // })
    // this.fetchCourses({ limit: 0 })

    // if (id) {
    //     this.isUpdating = true

    //     const found = this.prelations.find(prelation => prelation.id === parseInt(id))

    //     if (found) {
    //         this.prelation = {...found}
    //         this.setNavbarTitle(['Listado de prelaciones', 'Editar prelación'])
    //     } else {
    //         this.fetchPrelation(id)
    //     }
    // }
  }
}
</script>
